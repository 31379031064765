$color-white: #fff;

$color-dark-blue: #292f4c;
$color-gray: #929292;
$color-dark-gray: #4a4a4a;

$color-app-bg: #f7f7f7;

$color-disabled: #929292;
$color-status-0: #4a4a4a;
// $color-brand-1: #00baf2;
$color-brand-1: #007ab8;
$color-light-blue: #007ab8;

$color-btn-primary: #ff7f00;

// $color-error: #c21d00;
$color-error: #dc3545;
// $color-form-input-highlight: #0092d1;

$color-placeholder: #bfbfbf;
