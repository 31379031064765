/**
Idea from: 
https://www.w3schools.com/howto/howto_css_switch.asp 
https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_switch


Usage example:

  <label class="switch-toggle">
    Some text
    <div class="slider-wrapper">
      <input type="checkbox"/>
      <span class="slider round"></span>
    </div>
    Some text
  </label>

*/

.switch-toggle {
  position: relative;
  display: inline-block;
  line-height: 60px;

  font-size: 1rem;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider-wrapper {
    display: inline-block;
    width: 60px;
    height: 34px;
    position: relative;
    vertical-align: middle;
    margin: 0 1rem;

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #292f4c;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      &:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }

    // input:checked + .slider {
    //   background-color: #2196F3;
    // }

    // input:focus + .slider {
    //   box-shadow: 0 0 1px #2196F3;
    // }
  }
}
