// https://github.com/vmware/clarity/blob/e28c89b74aad11aeb861ca5cd4a569d9953e8b96/packages/angular/projects/clr-angular/src/layout/grid/mixins/_breakpoint.scss

@mixin breakpoint-up($name, $breakpoints: $app-breakpoints) {
  $min: map-get($breakpoints, $name);
  $min: $min + 1;
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin breakpoint-down($name, $breakpoints: $app-breakpoints) {
  $max: map-get($breakpoints, $name);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
