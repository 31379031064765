// example https://fontlibrary.org/face/metropolis

@font-face {
  font-family: 'Gotham';
  src: url('/assets/fonts/gotham/Gotham-Thin.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('/assets/fonts/gotham/Gotham-Book.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('/assets/fonts/gotham/Gotham-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
