.blackout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.4);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
  z-index: 999;

  &.blackout-white {
    background-color: rgba($color: #fff, $alpha: 0.4);
    color: inherit;
  }

  .spinner {
    opacity: 0.7;
  }
}
